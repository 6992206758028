@use 'common'

.wrapper
	--ContentImage-paragraph-vertical-spacing: 40px

	text-align: center
	margin: 0 auto
	margin-bottom: var(--ContentImage-paragraph-vertical-spacing)
	max-width: var(--content-width-thin)

	@media (min-width: common.$break768)
		--ContentImage-paragraph-vertical-spacing: 60px

.image
	border-radius: var(--border-radius)
