@use 'common'

.wrapper
	display: flex
	gap: 40px
	flex-direction: column

	@media (min-width: common.$break1000)
		flex-direction: row

.listNavigation,
.tiles
	flex-grow: 1
	flex-basis: 0
