@use 'common'

.wrapper
	display: none
	list-style-type: none
	padding: 0

	@media (min-width: common.$break1000)
		display: flex
		flex-wrap: wrap

.item
	color: common.$titleColor
	font-weight: 700

	&:last-child
		color: common.$textColor

	&:not(:last-child)::after
		content: '>'
		margin: 0 0.4em

.link
	color: inherit
	transition: color .3s ease-in-out

	&:hover, &:focus
		outline: 0
		color: common.$textColor
