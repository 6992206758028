.wrapper
	--heroTiles-gutter: 4px
	--heroTiles-topGutter: 50px
	--heroTiles-tileGap: 4px

	margin-top: var(--heroTiles-topGutter)

	@media (min-width: 480px)
		--heroTiles-tileGap: 10px
		--heroTiles-gutter: 20px

	@media (min-width: 580px)
		--heroTiles-tileGap: 15px

	@media (min-width: 768px)
		--heroTiles-topGutter: 60px

.main
	$maxWidth-item: 365px
	--heroTiles-columns: 1

	display: grid
	grid-template-columns: repeat(var(--heroTiles-columns), 1fr)
	gap: var(--heroTiles-tileGap)

	@media (min-width: 380px)
		--heroTiles-columns: 2

	@media (min-width: 768px)
		--gap-count: (var(--heroTiles-columns) - 1)
		--heroTiles-columns: 3

		max-width: calc(var(--heroTiles-columns) * #{$maxWidth-item} + var(--gap-count) * var(--heroTiles-tileGap))
		margin: 0 auto

.item
	width: 100%
	height: 100%
	grid-column: span 1
