@use 'common'

.columns,
.columnItems
	margin: 0
	padding: 0
	list-style-type: none

.columns
	display: grid
	grid-template-columns: repeat(auto-fill, minmax(200px, 1fr))
	gap: 2.5em 1em
	font-size: 15px
	margin: 0

.column
	//

	&Title
		font-size: 30px
		margin: 0 0 0.3em
		color: common.$titleColor
		font-family: common.$headingFont
		font-weight: 700

	&Items
		//

	&Item
		line-height: 1.2

		&Link
			display: inline-block
			padding: 0.3em 0
			text-transform: uppercase
			color: common.$textColor
			font-weight: 800
			font-family: common.$ralewayFont

			&:hover,
			&:focus
				outline: 0
				text-decoration: underline
