@use 'common'
@use 'sass:math'

.wrapper
	position: relative
	background: #f6f6f6
	margin-top: calc(-1 * var(--header-height) - 2em) // height of top navigation (--header-height = see globals.sass, 2em = 2 x header gutter)
	overflow: hidden
	padding-bottom: 20px

	@media (min-width: common.$break768)
		padding-bottom: 40px

	@media (min-width: 992px)
		padding-bottom: 60px

.background
	$ratio: math.div(924, 707)
	--hero-backgroundSize: 250%

	position: absolute
	top: 0
	padding-top: calc(var(--hero-backgroundSize) / #{$ratio})
	right: -70%
	left: -90%
	margin-top: -77%

	@media (min-width: common.$break768)
		--hero-backgroundSize: 190%
		right: -30%
		left: -60%
		margin-top: -59%

	@media (min-width: common.$break768)
		--hero-backgroundSize: 130%
		right: -50%
		left: -60%
		margin-top: -39%

	@media (min-width: 1200px)
		--hero-backgroundSize: 120%
		right: -40%
		left: -60%
		margin-top: -36%

	@media (min-width: 1350px)
		right: -50%
		left: -64%
		margin-top: -37%

.green,
.purple,
.turquoise
	position: absolute
	top: 0
	right: 0
	left: 0
	bottom: 0

.purple,
.turquoise
	mix-blend-mode: color

.isMobileBackground
	width: 100%
	height: 100%
	@media (min-width: common.$break768)
		display: none

.isDesktopBackground
	width: 100%
	height: 100%
	display: none

	@media (min-width: common.$break768)
		display: block

.main
	position: relative
	padding-top: 70px

	@media (min-width: common.$break768)
		padding-top: 40px

.mainIn
	text-align: center
	position: relative
	padding-top: 80px

	@media (min-width: 340px)
		padding-top: 95px

	@media (min-width: 480px)
		padding-top: 110px

	@media (min-width: 560px)
		padding-top: 165px

	@media (min-width: common.$break768)
		padding-top: 50px

.heroImage
	position: relative
	padding: 0 20px
	z-index: 5

	& > div
		transform: translateX(-3%)
		width: 100%

		@media (min-width: 340px)
			max-width: 240px

		@media (min-width: 340px)
			max-width: 260px

		@media (min-width: 560px)
			width: auto

		@media (min-width: 1350px)
			transform: translateX(-30px)

.title, .content
	position: relative
	margin: 0
	z-index: 1

.title
	--Hero-font-size-factor: 1
	--Hero-font-size-base: 50px
	position: absolute
	max-width: 300px
	width: 100%
	top: 0
	left: 50%
	transform: translateX(-50%)
	font-size: calc(var(--Hero-font-size-base) * var(--Hero-font-size-factor))
	line-height: 1

	.view_locale_en &
		--Hero-font-size-factor: 0.81

	@media (min-width: 340px)
		--Hero-font-size-base: 60px

	@media (min-width: 480px)
		--Hero-font-size-base: 70px

	@media (min-width: 560px)
		max-width: 500px
		--Hero-font-size-base: 100px

	@media (min-width: common.$break768)
		max-width: 100%
		top: 100px

	@media (min-width: common.$break1000)
		--Hero-font-size-base: 150px
		line-height: 130px

	@media (min-width: 1350px)
		--Hero-font-size-base: 200px
		line-height: 170px
		top: 80px

	&_secondPart, &_thirdPart, &_firstPart_firstWord
		display: inline-block
		position: relative

	&_firstPart
		position: relative
		z-index: 1
		color: #ffffff

		&_firstWord
			right: 6px

			@media (min-width: 480px)
				right: 8px

			@media (min-width: 560px)
				right: 12px

			@media (min-width: common.$break768)
				right: 0

		&_secondWord
			display: inline-block

			@media (min-width: common.$break768)
				display: inline

	&_secondPart
		right: 7%

		@media (min-width: 340px)
			right: 20px

		@media (min-width: 480px)
			right: 26px

		@media (min-width: 560px)
			right: 32px

		@media (min-width: common.$break768)
			right: 90px

		@media (min-width: common.$break1000)
			right: 80px

			.view_locale_en &
				right: 80px

		@media (min-width: 1350px)
			right: 105px

			.view_locale_en &
				right: 107px

	&_thirdPart
		left: 7%

		@media (min-width: 340px)
			left: 22px

		@media (min-width: 480px)
			left: 20px

		@media (min-width: 560px)
			left: 32px

		@media (min-width: common.$break768)
			left: 90px

		@media (min-width: common.$break1000)
			left: 70px

			.view_locale_en &
				left: 100px

		@media (min-width: 1350px)
			left: 30px

			.view_locale_en &
				left: 85px

.content
	margin-top: 40px
	text-align: center
	font-size: 25px
	line-height: 1

	@media (min-width: 560px)
		font-size: 30px

	@media (min-width: common.$break768)
		font-size: 40px

	&::after
		content: '>'
		display: block
		margin-top: 10px
		transform: rotate(90deg)
		font-size: 30px
		margin: 0

		@media (min-width: 560px)
			font-size: 35px

		@media (min-width: common.$break768)
			display: none
