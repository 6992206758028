@use 'common'

.wrapper
	position: absolute
	top: 0
	bottom: 0
	left: 0
	right: 0
	overflow: hidden
	-webkit-mask-image: -webkit-radial-gradient(white, black) // because of safari… (source: https://stackoverflow.com/a/49066720)

	&.view_borderRadius
		&_top, &_all
			border-top-left-radius: var(--border-radius)
			border-top-right-radius: var(--border-radius)

		&_bottom, &_all
			border-bottom-left-radius: var(--border-radius)
			border-bottom-right-radius: var(--border-radius)

.main
	position: relative
	width: 100%
	height: 100%
	transition: transform .3s ease-in-out
	background-color: rgba(common.$textColor, 0.3)

	&::after
		content: ''
		position: absolute
		top: 0
		right: 0
		bottom: 0
		left: 0
		background-color: #000000
		visibility: hidden
		opacity: 0
		transition-property: visibility, opacity
		transition-duration: 0.3s
		transition-timing-function: ease-in-out

	a:hover &,
	a:focus &
		transform: scale(1.02)

		&::after
			visibility: visible
			opacity: .2

	&.view_reversed_hover
		&::after
			visibility: visible
			opacity: .4

		a:hover &,
		a:focus &
			transform: none

			&::after
				visibility: hidden
				opacity: 0
