@use 'common'

$break: common.$break1000

.wrapper
	background-color: common.$c-gray-1
	padding: 3em 0 2em

.logo
	--footer-logo-maxHeight: 30px
	$aspectRatio: calc(var(--footer-logo-width) / var(--footer-logo-height))

	position: relative
	height: var(--footer-logo-maxHeight)
	width: calc(var(--footer-logo-maxHeight) * $aspectRatio)

	@media (min-width: 480px)
		--footer-logo-maxHeight: 40px

	@media (min-width: $break)
		--footer-logo-maxHeight: 62px

.navigation
	margin: 1.5em 0 3em

	@media (min-width: common.$break1000)
		margin: 2.5em 0 6em

.attribution
	display: flex
	flex-wrap: wrap
	gap: 0 1em
	font-size: 15px

	@media (min-width: $break)
		font-size: 20px

.copyright
	flex-grow: 1
	margin: 0

.copyright,
.mangoweb
	margin: 0
	padding: 0.5em 0

.mangoweb
	display: inline-flex
	align-items: center
	gap: 0.6ex

	a
		text-decoration: underline

		&:hover,
		&:focus
			outline: 0
			text-decoration: none

.mangowebLogo
	height: 0
	display: flex
	align-items: center
	font-size: 1.7em
