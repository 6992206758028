@use 'common'

.wrapper
	--couseTiles-display-value: inline-grid
	--courseTiles-item-spacing: 20px
	--courseTiles-horizontal-offset: calc(var(--courseTiles-item-spacing) * -1/2)
	--courseTiles-horizonzal-spacing: var(--page-horizontal-spacing)

	position: relative
	display: var(--couseTiles-display-value) // Magic: when parent has "display: inline-grid", then also child has to have "display: grid", otherwise it takes weird width and it will create horizontal scrollbar when "css carousel" is created.
	margin: 0 calc(-1 * var(--courseTiles-horizonzal-spacing))
	overflow: hidden

	@media (max-width: 768px)
		$gradientColor: #FFFFFF, rgba(#FFFFFF, 0.7), rgba(#FFFFFF, 0.4), rgba(#FFFFFF,0)

		&::before, &::after
			content: ''
			position: absolute
			top: 0
			bottom: 0
			width: 20px
			z-index: 1

		&::before
			background: linear-gradient(to right, $gradientColor)
			left: 0

		&::after
			background: linear-gradient(to left, $gradientColor)
			right: 0

	@media (min-width: 768px)
		--couseTiles-display-value: grid
		--courseTiles-horizonzal-spacing: 0

.main
	+common.horizontallyScrollableWithoutScrollbarOnSmallTouchDevice
	display: var(--couseTiles-display-value) // see Magic + must apply inline-grid, otherwise if there is only 1 item inside main then grid will be stretched
	grid-auto-flow: column
	padding-bottom: 20px

	&::before, &::after
		content: ''
		display: block
		width: calc(var(--page-horizontal-spacing) - var(--courseTiles-horizonzal-spacing) / 2)

	@media (min-width: 768px)
		grid-auto-flow: row
		grid-template-columns: 1fr 1fr 1fr 1fr
		overflow-x: initial
		gap: var(--courseTiles-item-spacing)

		&::before, &::after
			display: none

	@media (min-width: 992px)
		--courseTiles-item-spacing: 40px

.item
	width: 60vw
	display: flex
	padding: 0 calc(var(--courseTiles-item-spacing) / 2)

	@media (min-width: 420px)
		width: 40vw

	@media (min-width: 520px)
		width: 35vw

	@media (min-width: 768px)
		width: 100%
		padding: 0
