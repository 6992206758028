@use 'common' as *

.wrapper
	width: 100%
	position: relative
	background-color: $textColor
	border-radius: 50%

.main
	width: 100%
	padding-top: 100%

.icon
	width: 70%
	display: flex
	justify-content: center
	align-items: center
	position: absolute
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	margin-left: 2px

.view
	&_responsive
		width: 20%

	&_size
		&_small
			max-width: 32px

		&_middle
			max-width: 50px

			& .icon
				font-size: 40px

		&_big
			max-width: 66px

			& .icon
				font-size: 50px

	&_position
		position: absolute

		&_center
			top: 50%
			left: 50%
			transform: translate(-50%, -50%)
