.wrapper
	display: grid
	grid-template-columns: 1fr 1fr
	column-gap: 10px
	row-gap: 20px

	@media (min-width: 768px)
		grid-template-columns: 1fr 1fr 1fr

	@media (min-width: 992px)
		column-gap: 50px
		row-gap: 35px
