@use 'common'

.video
	border-radius: var(--border-radius)
	overflow: hidden

	@media (min-width: common.$break1000)
		margin-top: 60px

.content
	--coursePage-content-horizontal-spacing: 10px

	margin-top: 20px

	@media (min-width: common.$break768)
		display: flex
		justify-content: space-between
		flex-direction: row-reverse
		margin-top: 60px
		margin-left: calc(-1 * var(--coursePage-content-horizontal-spacing))
		margin-right: calc(-1 * var(--coursePage-content-horizontal-spacing))

.description, .attributes

	@media (min-width: common.$break768)
		width: 50%
		padding: 0 var(--coursePage-content-horizontal-spacing)

.description
	margin-top: 20px

	@media (min-width: common.$break768)
		margin: 0

.headline
	margin-top: -8px
	margin-bottom: 4px
	font-size: 20px
	line-height: 28px
	color: common.$titleColor2
	text-transform: uppercase

	@media (min-width: common.$break768)
		margin-bottom: 10px
		font-size: 50px
		line-height: 50px

	@media (min-width: common.$break1000)
		font-size: 70px
		line-height: 70px

.lead
	font-size: 1.125em // 18px
	line-height: 1.45
	margin: 0
	margin-bottom: 40px
	color: common.$textColor2

	@media (min-width: common.$break768)
		font-size: 1.625em // 26px
		line-height: 1.3
		margin-bottom: 60px

.attributes
	--coursePage-attributes-horizontal-spacing: 0
	--coursePage-attributes-vertical-spacing: 10px

	align-content: flex-start
	margin-top: calc(-1 * var(--coursePage-attributes-vertical-spacing))
	margin-bottom: calc(-1 * var(--coursePage-attributes-vertical-spacing))

	@media (min-width: common.$break768)
		--coursePage-attributes-horizontal-spacing: 20px
		position: sticky
		top: 50px
		margin: calc(-1 * var(--coursePage-attributes-vertical-spacing)) calc(-0.5 * var(--coursePage-attributes-horizontal-spacing))
		display: flex
		align-self: flex-start
		flex-wrap: wrap

.attribute
	display: flex
	flex-direction: column
	padding-top: var(--coursePage-attributes-vertical-spacing)
	padding-bottom: var(--coursePage-attributes-vertical-spacing)
	padding-left: var(--coursePage-attributes-horizontal-spacing)
	padding-right: var(--coursePage-attributes-horizontal-spacing)

	@media (min-width: common.$break768)
		&:not(:first-child)
			margin-top: 0

.newsletter
	padding-top: 20px
	padding-bottom: 28px
	padding-left: var(--coursePage-attributes-horizontal-spacing)
	padding-right: var(--coursePage-attributes-horizontal-spacing)
	margin-left: calc(-1 * var(--page-horizontal-spacing))
	margin-right: calc(-1 * var(--page-horizontal-spacing))

	@media (min-width: common.$break768)
		margin-left: 0
		margin-right: 0

.lessons
	margin: 20px 0

	&Title
		font-size: 18px
		margin: 0
		color: common.$titleColor
		text-transform: uppercase
		font-weight: 900
		font-family: common.$headingFont
		color: common.$titleColor2

	&List
		margin: 0.5em 0 0
		font-size: 20px

		li::marker
			color: inherit

	&Item
		&.is_locked
			color: common.$titleColor

	&Link
		color: common.$textColor

		&:hover,
		&:focus
			text-decoration: underline

	&Locked
		//
