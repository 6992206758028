@use "common"
@use "sass:math"

.wrapper
	position: relative
	background-color: common.$c-gray-1
	padding-top: 30px
	padding-bottom: 100px
	overflow: hidden

	@media (min-width: common.$break768)
		$verticalSpacing: 70px
		padding-top: $verticalSpacing
		padding-bottom: $verticalSpacing

.background
	$aspectRatio: math.div(924, 707)
	--subscribeBannerNewsletter-backgroundSize: 200%

	position: absolute
	top: 0
	padding-top: calc(var(--subscribeBannerNewsletter-backgroundSize) / #{$aspectRatio})
	right: -100%
	left: -100%
	margin-top: -40%

	@media (min-width: 480px)
		--subscribeBannerNewsletter-backgroundSize: 160%

	@media (min-width: 580px)
		--subscribeBannerNewsletter-backgroundSize: 150%

	@media (min-width: 768px)
		--subscribeBannerNewsletter-backgroundSize: 120%
		right: -25%
		left: -40%
		margin-top: -27%

	@media (min-width: 1300px)
		--subscribeBannerNewsletter-backgroundSize: 100%
		right: -50%
		left: -65%
		margin-top: -30%

	@media (min-width: 1700px)
		margin-top: -35%

.green,
.purple,
.turquoise
	position: absolute
	top: 0
	right: 0
	left: 0
	bottom: 0
	color: var(--subscribeBannerNewsletter-color)

	// @TODO: nezasahovat do cizí komponenty
	svg
		position: absolute
		top: 0
		left: 0
		width: 100%
		height: 100%

.purple,
.turquoise
	mix-blend-mode: color

.content

	@media (min-width: common.$break768)
		display: flex

.heading, .subscribe
	padding-top: 50px

.heading
	position: relative
	z-index: 1
	text-align: center

	@media (min-width: common.$break768)
		text-align: left
		margin-right: 50px

.logo
	--subscribeBannerNewsletter-logo-maxHeight: 30px
	$aspectRatio: calc(var(--subscribeBannerNewsletter-logo-width) / var(--subscribeBannerNewsletter-logo-height))

	height: var(--subscribeBannerNewsletter-logo-maxHeight)
	width: calc(var(--subscribeBannerNewsletter-logo-maxHeight) * $aspectRatio)
	position: relative
	margin: 0 auto
	margin-bottom: 10px

	@media (min-width: common.$break768)
		margin-left: 0
		margin-bottom: 20px

	@media (min-width: common.$break1000)
		--subscribeBannerNewsletter-logo-maxHeight: 40px

.title
	max-width: 270px
	margin: 10px auto 0
	font-size: 60px
	line-height: 40px
	font-weight: 900
	color: common.$textColor

	@media (min-width: common.$break768)
		max-width: 320px

	@media (min-width: common.$break1000)
		max-width: 400px
		font-size: 100px
		line-height: 80px

	@media (min-width: 1200px)
		max-width: 520px
		font-size: 130px
		line-height: 100px

.image
	position: relative
	margin-top: -15px
	text-align: center
	z-index: 1

	@media (min-width: common.$break768)
		order: 1
		margin-left: 20px
		margin-right: -50px

.subscribe
	position: relative
	margin: 0 auto
	margin-top: 40px
	max-width: 420px
	z-index: 1

	@media (min-width: common.$break768)
		margin: 0

	@media (min-width: 1300px)
		width: 100%

.text
	font-size: 25px
	line-height: 36px
	margin: 0

	@media (min-width: common.$break768)
		margin-top: 40px
		max-width: 200px

	@media (min-width: common.$break1000)
		margin-top: 75px
		max-width: 320px
		font-size: 35px
		line-height: 30px

	@media (min-width: 1200px)
		margin-top: 80px
		font-size: 40px
		line-height: 35px

.newsletter

	@media (min-width: 1200px)
		margin-top: 40px
