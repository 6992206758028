@use "common"

.wrapper

	& > *:not(:first-child)
		margin-top: 80px

		@media (min-width: common.$break1000)
			margin-top: 160px

.section

	@media (min-width: common.$break1000)
		display: flex
		flex-direction: column

.title
	margin-top: 30px

	@media (min-width: common.$break1000)
		order: -1
		margin-top: 0
		margin-bottom: 20px
