@use 'common'

.wrapper
	position: relative
	padding-top: 50px
	padding-bottom: 50px
	background-color: common.$c-gray-1

.content
	.has_message &
		filter: blur(4px)

.title
	margin: 0
	font-size: 25px
	line-height: 36px
	color: common.$c-gray-2

.subscribe
	max-width: 470px
	margin-top: 20px

.message
	position: absolute
	top: 0
	bottom: 0
	left: 0
	right: 0
	overflow: auto
	background-color: rgba(common.$c-gray-1, 0.8)
	padding: var(--page-horizontal-spacing)
	font-size: 1.7em
	text-align: center
	display: none
	justify-content: center
	align-items: center
	border: none
	color: inherit
	width: 100%
	height: 100%

	.has_message &
		display: flex
