@use 'common'
@use 'sass:math'

.main
	display: grid
	gap: 20px

	@media (min-width: 520px)
		grid-template-columns: minmax(50%, 1fr) minmax(auto, 480px)
		grid-template-rows: auto auto 1fr

	@media (min-width: 992px)
		column-gap: 55px
		row-gap: 40px

.content
	margin: 0 calc(-1 * var(--page-horizontal-spacing))

.name
	font-size: 26px
	line-height: 1.1
	color: common.$titleColor2
	text-transform: uppercase
	margin: 0

	@media (min-width: common.$break1000)
		font-size: 40px

	@media (min-width: 1300px)
		font-size: 70px

.imageColumn
	@media (min-width: 520px)
		grid-column: 2/3
		grid-row: span 3

.image
	$aspectRatio: math.div(480, 600)

	position: relative
	padding-top: math.div(100%, $aspectRatio)
	border-radius: var(--border-radius)
	overflow: hidden

	@media (min-width: 520px)
		position: sticky
		top: calc(61px + 2em + 20px) // height of top navigation (61px = hidingHeader, 2em = 2 x header gutter, 20px = add some spacing)

.sectionTitle
	font-size: 1.5625em // 25px

	@media (min-width: common.$break768)
		font-size: 2.25em // 36px
