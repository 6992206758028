@use 'common'

.wrapper
	margin-top: 5rem

.people
	margin-top: 2rem
	display: grid
	gap: 3rem 2rem
	grid-template-columns: repeat(auto-fill, minmax(250px, 1fr))

.person
	$avatar-size: 100px

	display: grid
	gap: 1rem
	font-size: 1.1em
	grid-template-rows: $avatar-size
	grid-auto-rows: min-content

	&Avatar
		width: $avatar-size
		height: $avatar-size
		position: relative
		border-radius: 50%
		overflow: hidden

	&Name
		margin: 0
		font-size: 1.5em
		font-weight: 700

	&About
		color: common.$textColor2

	&Email,
	&Phone
		text-decoration: underline

		&:hover,
		&:focus
			text-decoration: none
