@use 'common'

.wrapper
	width: 100%
	height: 100%
	position: absolute
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)

	&::after
		content: ''
		position: absolute
		top: 0
		right: 0
		bottom: 0
		left: 0
		background: common.$c-gray-1

.main
	display: flex
	justify-content: center
	align-content: center

.title
	position: absolute
	text-align: center
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	font-size: 25px
	line-height: 1.1
	z-index: 2
	margin: 0

	@media (min-width: common.$break768)
		font-size: 36px

.background
	position: absolute
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	font-size: 8em
	z-index: 1
	opacity: .5
