.wrapper
	$offsetTop: 60px
	display: grid
	grid-template-columns: 1fr
	row-gap: 50px

	@media (min-width: 768px)
		grid-template-columns: 1fr 1fr
		column-gap: 60px
		row-gap: 0

		&.view_offset_left // normal offset left (without main article)
			.item
				&:nth-child(2n + 1)
					padding-top: $offsetTop

		&.view_offset_right // normal offset left (without main article)
			.item
				&:nth-child(2n)
					padding-top: $offsetTop

		&.view_main

			.item
				&:first-child
					grid-column: span 2

			// offset with main article 'view_main' is upside down
			&.view_offset_left
				.item
					&:nth-child(2n)
						padding-top: $offsetTop

			&.view_offset_right
				.item
					&:nth-child(2n + 1)
						padding-top: $offsetTop

			&.view_offset_left
				.item
					&:nth-child(2n + 1)
						padding-top: 0

			&.view_offset_right
				.item
					&:nth-child(2n)
						padding-top: 0

.item

	@media (min-width: 768px)
		grid-column: span 1
		padding-bottom: 30px

		&:nth-last-child(2)
				padding-bottom: 0

		&:last-child
			padding-bottom: 0
