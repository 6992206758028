@use '../app/styles/common'

$iconOffsetLeft: 4px
$iconOffsetTransitionLeft: 4px

.wrapper
	--sectionTitle-icon-offset-top: -3px
	display: block

	a:hover, a:focus
		outline: 0

		.title
			color: common.$textColor

			&::after
				transform: translate($iconOffsetTransitionLeft, var(--sectionTitle-icon-offset-top))

	@media (min-width: common.$break1000)
		&.view
			&_center
				text-align: center

			&_right
				text-align: right

.title
	display: inline-block
	position: relative
	margin: 0
	font-size: 40px
	line-height: 47px
	font-weight: 900
	text-transform: uppercase
	transition: color .3s ease-in-out
	color: common.$titleColor

	&::after
		content: '>'
		display: inline-block
		transform: translateY(var(--sectionTitle-icon-offset-top))
		margin-left: $iconOffsetLeft
		transition: transform .3s ease-in-out

	@media (min-width: common.$break1000)
		--sectionTitle-icon-offset-top: -6px

		font-size: 70px
