$niceColor: #57606f
$textColor: #324F5D
$textColor2: #575656
$titleColor: #9E9AAD
$titleColor2: #DBC3C0
$c-gray-1: #F7F7F7
$c-gray-2: #A3AFA8

$break768: 768px
$break1000: 1000px

$brendonFont: "Brandon Grotesque"
$bodyFont: "PT Sans", sans-serif
$headingFont: $brendonFont, Calibri, $bodyFont
$ralewayFont: Raleway
$ralewayBodyFont: $ralewayFont, $bodyFont
