@use 'common'

$break: common.$break1000

.wrapper
	text-align: center
	margin-bottom: 28px

	@media (min-width: $break)
		margin-bottom: 92px

.category
	font-weight: 700
	margin-top: 8px
	text-transform: uppercase
	font-family: common.$headingFont
	color: #9E9AAD
	font-size: 18px
	display: flex
	align-items: center
	justify-content: center

	@media (min-width: $break)
		font-size: 20px

	&::before,
	&::after
		content: ''
		height: 2px
		background-color: currentColor
		width: 0.7em
		margin: 0 0.1em

	&Link
		color: inherit

		&:hover,
		&:focus
			outline: 0
			text-decoration: underline

.headline
	text-transform: uppercase
	color: common.$titleColor2
	margin: 0
	font-size: 30px
	font-weight: 900
	line-height: 1
	margin-top: 12px

	@media (min-width: $break)
		font-size: 70px
		line-height: 1.14
		margin-top: 36px

.lead
	margin-top: 10px
	line-height: 1.3125

	@media (min-width: $break)
		font-size: 26px

.image
	margin-top: 20px

	@media (min-width: $break)
		margin-top: 48px
		border-radius: var(--border-radius)
		overflow: hidden
