@use 'common'

.wrapper
	display: flex

.label
	width: 100%
	display: flex
	align-items: flex-end

.input
	width: 100%
	padding: 5px 8px
	border: 0
	border-bottom: 1px solid common.$c-gray-2
	font-size: 20px
	line-height: 26px
	background-color: transparent
	color: common.$textColor

	&::placeholder
		padding: 5px 5px
		color: common.$textColor

	&:focus
		outline: 0
		border-color: common.$textColor

.button
	display: flex
	align-items: flex-end
	flex-shrink: 0
	margin-left: 20px

.submit
	$borderRadius: var(--border-radius)

	border: 0
	font-family: common.$ralewayBodyFont
	font-weight: 800
	font-size: 15px
	line-height: 18px
	text-transform: uppercase
	border-radius: $borderRadius
	transition-property: background-color, color
	transition-duration: .3s
	transition-timing-function: ease-in-out

	&.view_fill
		padding: 14px 12px
		color: #FFFFFF
		background-color: common.$textColor

	&.view_transparent
		padding: 10px 12px
		background-color: transparent
		color: common.$textColor

	&:hover, &:focus
		outline: 0
		background-color: common.$titleColor2
		color: common.$textColor
