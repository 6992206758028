@use '../app/styles/common'

.wrapper
	--poseCategoryChips-item-horizontal-spacing: 6px
	--poseCategoryChips-item-vertical-spacing: 10px

	display: flex
	flex-wrap: wrap
	margin: calc(-1 * var(--poseCategoryChips-item-vertical-spacing) / 2) calc(-1 * var(--poseCategoryChips-item-horizontal-spacing) / 2)
	@media (min-width: common.$break1000)
		--poseCategoryChips-item-horizontal-spacing: 45px
		--poseCategoryChips-item-vertical-spacing: 25px

		justify-content: center

.item
	padding: calc(var(--poseCategoryChips-item-vertical-spacing) / 2) calc(var(--poseCategoryChips-item-horizontal-spacing) / 2)
