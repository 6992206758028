@use 'common'
@use 'sass:math'

$articleTileTopGutter: 10px

.wrapper
	--articleTile-columns: 1fr 1fr
	--articleTile-gap: 20px

	&:hover, &:focus
		outline: 0

		.more
			text-decoration: underline

	@media (min-width: 768px)

		&.view_main

			.main
				--articleTile-columns: 1fr 1fr
				--articleTile-gap: 60px

.main

	@media (min-width: 340px)
		display: grid
		grid-template-columns: var(--articleTile-columns)
		gap: var(--articleTile-gap)

	@media (min-width: 768px)
		--articleTile-columns: 1fr
		--articleTile-gap: 15px

.image
	$aspectRatio: math.div(600, 378)

	padding-top: math.div(100%, $aspectRatio)
	height: 0
	position: relative

	@media (max-width: 339px)
		margin-bottom: 10px

.category
	margin-top: 0
	margin-bottom: 0
	font-family: common.$bodyFont
	font-size: 16px
	line-height: 23px
	letter-spacing: .1em
	text-transform: uppercase
	color: common.$titleColor
	font-weight: 400

	@media (min-width: 340px)
		margin-top: -5px

	&::before, &::after
		content: '—'
		display: inline-block

.title
	font-family: common.$bodyFont
	font-size: 18px
	line-height: 23px
	margin: 0

	@media (min-width: 768px)
		font-size: 25px
		line-height: 32px

	@media (min-width: 992px)
		font-size: 40px
		line-height: 52px

	&:not(:first-child)
		margin-top: $articleTileTopGutter

.text
	&:not(:first-child)
		margin-top: $articleTileTopGutter

	@media (min-width: 992px)
		font-size: 20px
		line-height: 26px

.more
	display: none
	font-size: 20px
	font-weight: 700
	line-height: 26px

	&:not(:first-child)
		margin-top: $articleTileTopGutter

	@media (min-width: 768px)
		display: block
