@use 'common'

$break: common.$break1000

.wrapper
	padding-bottom: 100px

	@media (min-width: $break)
		padding-bottom: 60px

.navigation
	+common.horizontallyScrollableWithoutScrollbarOnSmallTouchDevice
	display: flex
	margin: 1em 0
	padding: 0
	list-style-type: none
	font-family: common.$ralewayBodyFont

	@media (min-width: $break)
		display: none

	&::before,
	&::after
		content: ''
		width: var(--page-horizontal-spacing)
		flex-shrink: 0

	&Item
		&:not(:last-child)
			margin-right: 3em

	&Link
		white-space: nowrap
		color: common.$titleColor
		text-transform: uppercase
		font-size: 15px
		font-weight: 800

		&:hover,
		&:focus
			outline: 0
			text-decoration: underline

		&.is_active
			color: common.$textColor

.item
	@media (min-width: $break)
		padding-top: 60px
