@use 'common'
@use 'sass:math'

$font-size: 18px
$base-height: 2.5em // 45px
$line-height: 1em * math.div(26px, $font-size)

.wrapper
	display: inline-flex
	align-items: center
	background-color: common.$c-gray-1
	border-radius: math.div($base-height, 2)
	line-height: $line-height
	padding: math.div($base-height - $line-height, 2) math.div($base-height, 4)
	font-size: $font-size
	min-width: 8em
	transition: background-color .3s ease-in-out

	&:is(a)
		&:hover,
		&:focus
			outline: 0
			background-color: rgba(common.$c-gray-2, .5)

.icon
	margin-right: 0.4em
	position: relative
	height: $line-height
	width: calc(var(--chip-icon-aspectRatio) * #{$line-height})

.text
	color: common.$textColor
	font-weight: bold
	padding: 0 math.div($base-height, 4)

	.icon + &
		padding-left: 0
