@use 'common'

.wrapper
	scroll-margin-top: 6em

	& + &
		margin-top: 60px

		@media (min-width: common.$break1000)
			margin-top: 80px

.list
	margin-top: 16px

	@media (min-width: common.$break1000)
		margin-top: 34px
