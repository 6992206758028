@use 'common'

.wrapper
	padding-bottom: 32px
	text-align: center

	@media (min-width: common.$break1000)
		padding-bottom: 80px
		font-size: 2em

.title
	margin: 1em 0 0.5em
	line-height: 1.05
	color: common.$titleColor2
	text-transform: uppercase

.returnLink
	&:hover,
	&:focus
		text-decoration: underline
