@use 'common'

.wrapper
	margin-bottom: 40px

	@media (min-width: common.$break1000)
		margin-bottom: 80px

.list
	margin: 0
	padding: 0
	list-style-type: none

.item
	//

.button
	border: none
	padding: 0.1em 0
	line-height: 1
	background-color: transparent
	color: common.$titleColor
	font-size: 40px
	font-weight: 900
	font-family: common.$headingFont
	text-transform: uppercase
	text-align: inherit
	transition: color .3s ease-in-out

	&:hover, &:focus
		outline: 0
		color: rgba(common.$textColor, .7)

	@media (min-width: common.$break1000)
		font-weight: 700
		font-size: 70px

	&.is_active
		color: common.$textColor
