@use 'common'
@use 'sass:math'

$break: common.$break1000
$breakM: $break - 1px
$gutter-height-desktop: 1rem
$transition-duration: 0.2s
$basePlate-zIndex: 8

.inner,
.gutter
	background-color: #FFFFFF
	transition: background-color $transition-duration

	&.is_home:not(.is_submenuOpen)
		background-color: transparent

	&.is_submenuOpen
		@media (max-width: $breakM)
			background-color: common.$c-gray-1

.inner
	position: relative

.gutter
	position: relative
	z-index: $basePlate-zIndex + 1
	height: 1.25rem

	@media (min-width: $break)
		height: $gutter-height-desktop

.content
	display: grid
	grid-template-columns: 1fr auto
	padding: 0.75em 0
	gap: 1em
	color: common.$textColor

.logo
	--header-logo-maxHeight: 20px
	$aspectRatio: calc(var(--header-logo-width) / var(--header-logo-height))

	height: var(--header-logo-maxHeight)
	width: calc(var(--header-logo-maxHeight) * $aspectRatio)
	position: relative

	@media (min-width: 380px)
		--header-logo-maxHeight: 30px

	@media (min-width: $break)
		--header-logo-maxHeight: 40px

	&:hover, &:focus
		outline: 0

		.logoIn
			transform: scale(1.05)

	&In
		width: 100%
		height: 100%
		position: absolute
		top: 0
		right: 0
		bottom: 0
		left: 0
		transition: transform .3s ease-in-out

.menuToggle
	$extraClickableArea: 0.5em

	justify-self: flex-end
	text-transform: uppercase
	font-weight: 800
	padding: $extraClickableArea
	margin: -$extraClickableArea
	display: inline-block
	border: none
	background-color: transparent
	font-size: 15px
	color: inherit
	transition: transform .3s ease-in-out

	&:hover, &:focus
		outline: 0
		transform: scale(1.05)

	@media (min-width: $break)
		display: none

.navigation
	font-size: 20px
	display: none

	@media (min-width: $break)
		display: flex
		align-items: center
		justify-self: flex-end

	&List
		padding: 0
		display: flex
		flex-wrap: wrap
		list-style-type: none
		margin: 0 calc(-1 * var(--page-horizontal-spacing) / 2)

	&Item
		padding: 0 3em

		&:first-child
			padding-left: calc(var(--page-horizontal-spacing) / 2)

		&:last-child
			padding-right: calc(var(--page-horizontal-spacing) / 2)

	&Link
		font-size: 20px
		line-height: 1.3
		color: inherit

		&.isHighlighted
			// @TODO: button
			$borderRadius: var(--border-radius)

			border: 0
			font-family: common.$ralewayBodyFont
			font-weight: 800
			font-size: 15px
			line-height: 18px
			text-transform: uppercase
			border-radius: $borderRadius
			transition-property: background-color, color
			transition-duration: .3s
			transition-timing-function: ease-in-out

			&.view_fill
				padding: 14px 12px
				color: #FFFFFF
				background-color: common.$textColor

			&:hover, &:focus
				outline: 0
				background-color: common.$titleColor2
				color: common.$textColor
				text-decoration: none

		&:hover,
		&:focus
			outline: 0
			text-decoration: underline

.submenu
	background-color: common.$c-gray-1
	position: absolute
	top: 100%
	left: 0
	right: 0
	border-radius: 0px 0px 40px 40px
	opacity: 0
	visibility: hidden
	transition-property: opacity, visibility
	transition-duration: $transition-duration

	&.is_open
		opacity: 1
		visibility: visible

	@media (min-width: $break)
		border-top: $gutter-height-desktop solid #FFFFFF

.submenuBackgroundColoser
	position: fixed
	z-index: $basePlate-zIndex
	top: 0
	left: 0
	right: 0
	bottom: 0
	width: 100%
	height: 100%
	border: none
	background-color: rgba(#000000, 0.2)
	display: block
	opacity: 0
	visibility: hidden
	transition-property: opacity, visibility, background-color
	transition-duration: $transition-duration
	cursor: auto
	outline: none

	&:focus
		background-color: rgba(#000000, 0.3)

	&.is_open
		opacity: 1
		visibility: visible
