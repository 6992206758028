=horizontallyScrollableWithoutScrollbarOnSmallTouchDevice
	overflow-x: auto
	-webkit-overflow-scrolling: touch

	@media (hover: none), (pointer: coarse)
		scrollbar-width: none
		scrollbar-height: none
		-ms-overflow-style: none

		&::-webkit-scrollbar
			display: none
