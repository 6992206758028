@use "common"
@use "sass:math"

.wrapper
	position: relative
	display: flex
	width: 100%

	&:focus
		outline: 0

.main
	width: 100%
	display: flex
	flex-direction: column

.image
	$aspectRatio: math.div(292,352)

	position: relative
	padding-top: math.div(100%, $aspectRatio)

.labels
	position: absolute
	top: 0
	left: 0
	padding: 15px 10px

.label
	width: fit-content
	padding: 5px 12px
	border-radius: 14px
	font-size: 15px
	line-height: 19px
	font-weight: bold

	&:not(:first-child)
		margin-top: 8px

	&.view
		&_difficulty
			background-color: common.$c-gray-1
			color: common.$textColor2

		&_lessonsCount
			background-color: #A19ED7
			color: #FFFFFF

.content
	display: flex
	flex-direction: column
	flex-grow: 1
	padding: 12px
	padding-bottom: 20px
	border: 1px solid #EFEFEF
	border-bottom-left-radius: var(--border-radius)
	border-bottom-right-radius: var(--border-radius)

.headline
	margin: 0
	font-family: common.$bodyFont
	font-size: 18px

.shortLead
	margin-top: 10px
	font-size: 14px

	&:not(:last-child)
		margin-bottom: 25px

.button
	margin-top: auto
	font-family: common.$ralewayBodyFont
	font-size: 15px
	font-weight: 800
	text-transform: uppercase
