@use 'common'

.wrapper
	position: relative
	width: 100%
	padding-top: calc(100% / var(--video-aspect-ratio-mobile))
	background-color: common.$c-gray-1

	@media (min-width: common.$break1000)
		padding-top: calc(100% / var(--video-aspect-ratio-desktop))

.main
	width: 100%
	height: 100%
	position: absolute
	top: 0
	right: 0
	bottom: 0
	left: 0
