@use 'common'
@use 'sass:math'

.wrapper
	$aspectRatio: math.div(292,352)

	display: block
	position: relative
	padding-top: math.div(100%, $aspectRatio)

	&:hover,
	&:focus
		outline: 0

		.name
			opacity: 0
			visibility: hidden

.name
	position: absolute
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	text-align: center
	transition-property: visibility, opacity
	transition-duration: .3s
	transition-timing-function: ease-in-out
	z-index: 2
	color: #FFFFFF
