@use "../app/styles/common"

.label
	margin: 0
	font-family: common.$ralewayBodyFont
	font-size: 15px
	line-height: 18px
	font-weight: 800
	text-transform: uppercase

.text
	font-family: common.$headingFont
	font-size: 26px
	line-height: 37px
	font-weight: 700

.link
	&:hover,
	&:focus
		text-decoration: underline
