@use 'common'

.wrapper
	--tileList-min-tile-width: 140px

	display: grid
	grid-template-columns: repeat(auto-fill, minmax(var(--tileList-min-tile-width), auto))
	gap: 16px

	@media (min-width: common.$break1000)
		--tileList-min-tile-width: 200px
