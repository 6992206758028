@use "sass:math"

.wrapper
	&:focus
		outline: 0

.main
	$aspectRatio: math.div(352, 222)
	position: relative

	&::before
		content: ''
		display: block
		padding-top: math.div(100%, $aspectRatio)

	&::after
		content: ''
		position: absolute
		top: 0
		right: 0
		bottom: 0
		left: 0
		background: linear-gradient(0deg, rgba(50, 79, 93, 0.35), rgba(50, 79, 93, 0.35))
		z-index: 1
		border-radius: var(--border-radius)

.headline
	width: 100%
	position: absolute
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	padding: 0 5px
	margin: 0
	text-align: center
	font-size: 16px
	font-weight: 400
	z-index: 2
	color: #FFFFFF

	@media (min-width: 350px)
		font-size: 20px

	@media (min-width: 480px)
		font-size: 26px
		padding: 0 10px

	@media (min-width: 580px)
		font-size: 30px

	@media (min-width: 680px)
		font-size: 36px

	@media (min-width: 768px)
		font-size: 30px

	@media (min-width: 992px)
		font-size: 36px

	@media (min-width: 1200px)
		padding: 0 20px
