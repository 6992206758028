@use 'common'

.wrapper
	--wysiwyg-paragraph-vertical-spacing: 40px
	--wysiwyg-list-item-spacing: 10px

	h1, h2, h3, h4
		font-family: common.$bodyFont
		margin: 0
		line-height: 1.3

	h4
		font-size: 0.875em // 14px
		text-transform: uppercase

		@media (min-width: common.$break768)
			font-size: 1em // 16px

	h3
		font-size: 1.125em // 18px

		@media (min-width: common.$break768)
			font-size: 1.5625em // 25px

	h2
		font-size: 1.5625em // 25px

		@media (min-width: common.$break768)
			font-size: 2.25em // 36px

	h1
		font-size: 2.25em // 36px

		@media (min-width: common.$break768)
			font-size: 2.625em // 42px

	p
		margin-bottom: var(--wysiwyg-paragraph-vertical-spacing)

	ul li,
	ol li
		margin-bottom: var(--wysiwyg-list-item-spacing)

		@media (min-width: common.$break768)
			--wysiwyg-list-item-spacing: 20px

	p,
	ul li,
	ol li
		font-size: 1.125em // 18px
		line-height: 1.45
		color: common.$textColor2

		@media (min-width: common.$break768)
			--wysiwyg-paragraph-vertical-spacing: 60px

			font-size: 1.625em // 26px
			line-height: 1.3

	h1 + p, h2 + p, h3 + p, h4 + p
		margin-top: .5em

	* + p
		margin-top: var(--wysiwyg-paragraph-vertical-spacing)

	a
		text-decoration: underline
		color: common.$textColor
		font-weight: 700

		&:hover, &:focus
			outline: 0
			text-decoration: none
