@use "sass:math"
@use "common"

$chip-gap: 5px

.wrapper

	@media (min-width: 600px)
		display: grid
		grid-template-columns: minmax(50%, 1fr) minmax(auto, 578px)
		gap: 20px

	@media (min-width: 992px)
		column-gap: 55px
		row-gap: 40px

.headline
	font-size: 26px
	line-height: 1.1
	color: common.$titleColor2
	text-transform: uppercase
	margin: 0

	@media (min-width: 360px)
		font-size: 30px

	@media (min-width: 600px)
		font-size: 26px

	@media (min-width: common.$break1000)
		font-size: 40px

	@media (min-width: 1300px)
		font-size: 70px

		&.view_containsLongWord
			font-size: 60px

.subtitle
	font-size: 26px
	line-height: 37px
	margin: 0

	@media (min-width: 768px)
		font-size: 36px
		line-height: 51px

.chips
	display: flex
	flex-wrap: wrap
	margin: 0 (-$chip-gap)

.chip
	padding: $chip-gap

.imageColumn

	@media (min-width: 600px)
		position: sticky
		top: 50px

.image
	$aspectRatio: math.div(578, 382)

	position: relative
	padding-top: math.div(100%, $aspectRatio)
	margin-top: 30px
	border-radius: var(--border-radius)
	overflow: hidden
	z-index: 0

	&::after
		content: ''
		background-color: rgba(common.$textColor, .2)
		position: absolute
		top: 0
		right: 0
		bottom: 0
		left: 0
		z-index: 1

	@media (min-width: 600px)
		margin-top: 0

.content
	margin-top: 45px

	@media (min-width: 600px)
		margin-top: 0
