@use 'sass:math'

=normalizeSize($intrinsicWidth, $intrinsicHeight)
	width: math.div($intrinsicWidth, $intrinsicHeight) * 1em
	height: 1em

.wrapper
	//

.icon
	&-go
		+normalizeSize(512, 512)

	&-jogaOnlineLogo
		+normalizeSize(239, 35)

	&-jogaLogo
		+normalizeSize(34, 28)

	&-lock
		+normalizeSize(24, 24)

	&-playButton
		+normalizeSize(43, 50)

	&-plnevedomiLogo
		+normalizeSize(515, 62)
