.wrapper
	display: grid
	grid-template-rows: auto auto auto 1fr auto // First three rows belong to Header component
	min-height: 100vh

.main
	//

.footer
	//
