@use 'sass:math'

.wrapper

	&:focus
		outline: 0

.image
	width: 100%
	height: 100%
	position: relative

	&::before
		$aspectRatio: math.div(365, 230)

		content: ''
		display: block
		padding-top: math.div(100%, $aspectRatio)

.title
	$horizontalSpacing: 10px
	display: flex
	justify-content: space-between
	align-items: center
	position: absolute
	bottom: 10px
	left: $horizontalSpacing
	right: $horizontalSpacing
	margin: 0
	font-size: 18px
	line-height: 1.1
	color: #ffffff

	@media (min-width: 380px)
		font-size: 20px

	@media (min-width: 480px)
		font-size: 25px

	@media (min-width: 580px)
		font-size: 30px

	&::after
		content: '>'
		display: block
		margin-left: 5px

		@media (min-width: 768px)
			display: none
