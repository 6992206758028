@use 'common'

.wrapper
	display: grid
	gap: 20px

	@media (min-width: 480px)
		grid-template-columns: repeat(auto-fill, minmax(200px, 1fr))

	@media (min-width: common.$break1000)
		grid-template-columns: 1fr 1fr 1fr 1fr
